exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-capability-tsx": () => import("./../../../src/pages/capability.tsx" /* webpackChunkName: "component---src-pages-capability-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-case-study-template-tsx": () => import("./../../../src/templates/CaseStudyTemplate.tsx" /* webpackChunkName: "component---src-templates-case-study-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/PostTemplate.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */)
}

